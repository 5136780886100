$(function() {
  var $win = $(window),
      $mv = $('.mv'),
      $h_img = $('.head_img'),
      $header = $('header'),
      $case_bc = $('#case_detail .breadcrumb'),
      headHeight = $header.outerHeight(),
      headPos = $header.offset().top,
      fixedClass = 'is-fixed';

  $win.on('load scroll', function() {
    var value = $(this).scrollTop();
    if ( value > headPos ) {
      $header.addClass(fixedClass);
      $mv.css('margin-top', '107px');
      $h_img.css('margin-top', '107px');
      $case_bc.css('margin-top', '107px');
    } else {
      $header.removeClass(fixedClass);
      $mv.css('margin-top', '0');
      $h_img.css('margin-top', '0');
      $case_bc.css('margin-top', '0');
    }
  });

  var winW = $(window).width();
  var devW = 480;
  if (winW <= devW) {
    $win.on('load scroll', function() {
      var value = $(this).scrollTop();
      if ( value > headPos ) {
        $header.addClass(fixedClass);
        $mv.css('margin-top', '75.5px');
        $h_img.css('margin-top', '75.5px');
        $case_bc.css('margin-top', '75.5px');
      } else {
        $header.removeClass(fixedClass);
        $mv.css('margin-top', '0');
        $h_img.css('margin-top', '0');
        $case_bc.css('margin-top', '0');
      }
    });
  }
});

// modal
$('#manual-ajax').click(function(event) {
  event.preventDefault();
  this.blur(); // Manually remove focus from clicked link.
  $.get(this.href, function(html) {
    $(html).appendTo('body').modal();
  });
});

// メニューオープン
$(function () {
  var $body = $('body');

  //開閉用ボタンをクリックでクラスの切替え
  $('#js__btn').on('click', function () {
    $body.toggleClass('open');
  });

  //メニュー名以外の部分をクリックで閉じる
  $('#js__nav').on('click', function () {
    $body.removeClass('open');
  });
});


//スムーススクロール
$(function() {
  var topBtn = $('#page-top');
  topBtn.hide();
  //スクロールが100に達したらボタン表示
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  });
  //スクロールしてトップ
  topBtn.click(function () {
    $('body,html').animate({
      scrollTop: 0
    }, 500);
    return false;
  });
});

$(function(){
  $('a[href^="#"]').click(function(){
    var speed = 500;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
});

//スライダー
$(function() {
  $('.slider').slick({
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    infinite: true,
    speed: 1000,
    arrows: false,
    centerMode: false,
    centerPadding:'0px',
    slidesToShow: 4,
    slidesToScroll: 1,
    useTransform: true,
    swipe: true,
    swipeToSlide: true,
    adaptiveHeight: true,
    initialSlide: 0,
    responsive: [{
      breakpoint: 769,
      settings: {
        slidesToShow:3,
        centerPadding:'0px',
        swipeToSlide: true,
      },
      breakpoint: 481,
      settings: {
        autoplay: false,
        centerMode: true,
        slidesToShow:1,
        centerPadding:'0px',
      }
    }]
  });
  $('.slider-2').slick({
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    infinite: true,
    speed: 1000,
    arrows: true,
    centerMode: true,
    centerPadding:'0px',
    slidesToShow: 3,
    slidesToScroll: 1,
    useTransform: true,
    swipe: true,
    swipeToSlide: true,
    adaptiveHeight: true,
    initialSlide: 0,
    responsive: [{
      breakpoint: 769,
      settings: {
        slidesToShow:3,
        centerPadding:'0px',
        swipeToSlide: true,
      },
      breakpoint: 481,
      settings: {
        autoplay: false,
        centerMode: true,
        slidesToShow:1,
        centerPadding:'0px',
      }
    }]
  });
  $('#').slick();
  $('.slider').bind('touchstart', function(){
    return true;
  });
});

// $(function () {
//   const loop = document.getElementById('loop_logo');
//
//   //タイムラインを作成
//   const loopAnim = new TimelineMax({
//     repeat: -1 //繰り返し無制限
//   });
//
//   //画像を複製してdiv内に挿入
//   // let loopItem = (window.innerWidth, loop.children[0]);
//   // loop.appendChild(loopItem.cloneNode(true));
//   // loop.appendChild(loopItem.cloneNode(true));
//
//   //アニメーションの設定
//   loopAnim
//       .to(loop, 50, { ease: Power0.easeNone, xPercent: -66.66666 })
//       .to(loop, 0, { ease: Power0.easeNone, x: 0 });
// });

